import { breakpoints } from '@iheartradio/web.accomplice';
import { InlineAdContainer } from '@iheartradio/web.accomplice/display-ads';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { type SpacerProps, Spacer } from '@iheartradio/web.accomplice/spacer';
import { Playback } from '@iheartradio/web.playback';
import { useDeepCompareEffect } from '@iheartradio/web.remix-shared/react/use-deep-compare-effect.js';
import { isNotBlank } from '@iheartradio/web.utilities';
import type { ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { isEmpty } from 'remeda';
import { useMediaQuery } from 'usehooks-ts';

import { useDisplayTargeting } from '~app/ads/display/targeting';
import { playback } from '~app/playback/playback';

import { GoogleAds, useDisplayAdsContext } from './ads';
import { CompanionAd } from './companion-ad';
import { handler } from './handler';
import { adClickHandler } from './nav-ad';
import { useAdUnit } from './use-ad-unit';
import { useDisplayAd } from './use-display-ad';

const MOBILE_INLINE_AD_SIZES = [320, 50] as [number, number];
const DESKTOP_INLINE_AD_SIZES = [728, 90] as [number, number];

type SpacerSpacing = Pick<
  SpacerProps,
  'bottom' | 'top' | 'left' | 'right' | 'x' | 'y'
>;

type InlineAdSlotProps = {
  isGrid?: boolean;
  noWrapper?: boolean;
  _section?: string;
  spacerProps?: Partial<
    Record<keyof SpacerSpacing, SpacerSpacing[keyof SpacerSpacing]>
  >;
};

const defaultSpacerProps = {} as Partial<
  Record<keyof SpacerSpacing, SpacerSpacing[keyof SpacerSpacing]>
>;

export function InlineAd({
  isGrid = false,
  noWrapper = false,
  _section = 'default',
  spacerProps = defaultSpacerProps,
}: InlineAdSlotProps) {
  const isLarge = useMediaQuery(breakpoints.large);

  const Wrapper = useMemo(
    () =>
      noWrapper ?
        ({ children }: { children: ReactNode; isCompanion?: boolean }) =>
          !isEmpty(spacerProps) ?
            <Spacer {...spacerProps}>{children}</Spacer>
          : <>{children}</>
      : ({
          children,
          isCompanion,
        }: {
          children: ReactNode;
          isCompanion?: boolean;
        }) => (
          <Flex
            alignItems="center"
            data-test={
              isCompanion ?
                'inline-companion-container'
              : 'inline-advert-container'
            }
            direction="row"
            display={{ mobile: 'flex' }}
            minHeight={{
              small: '5rem',
              medium: '9rem',
            }}
            placeContent="center"
            position="relative"
            width="100%"
            {...(isGrid ?
              {
                gridColumn: '1 / -1',
                gridRow: '2',
              }
            : {})}
          >
            {!isEmpty(spacerProps) ?
              <Spacer {...spacerProps}>{children}</Spacer>
            : children}
          </Flex>
        ),
    [isGrid, noWrapper, spacerProps],
  );

  const adUnitPath = useAdUnit();

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '0% 0% -20% 0%',
  });

  const playerState = playback.useState();
  const playerAdsState = playback.useAds();

  const [companion, setCompanion] = useState<JSX.Element | null>(null);

  const [displayState, setDisplayState] = useState({
    displayAd: false,
    companionAd: false,
  });

  const { display } = useDisplayAd();
  const { enabled: displayAdsEnabled } = useDisplayAdsContext();

  const targeting = useDisplayTargeting();

  useDeepCompareEffect(() => {
    if (
      display &&
      ((playerAdsState.status === Playback.AdPlayerStatus.Idle &&
        playerState.status === Playback.Status.Idle) ||
        playerAdsState.status === Playback.AdPlayerStatus.Done) &&
      isNotBlank(adUnitPath)
    ) {
      setDisplayState(previousProps => ({
        ...previousProps,
        displayAd: true,
      }));
    } else {
      setDisplayState(previousProps => ({
        ...previousProps,
        displayAd: false,
      }));
    }
  }, [adUnitPath, display, playerAdsState]);

  useDeepCompareEffect(() => {
    if (
      displayState.displayAd &&
      adUnitPath &&
      !displayState.companionAd &&
      inView
    ) {
      GoogleAds.receiveTargeting(targeting);
      GoogleAds.addSlot(
        adUnitPath,
        isLarge ? DESKTOP_INLINE_AD_SIZES : MOBILE_INLINE_AD_SIZES,
        'inline',
        {
          ccrpos: '2014',
        },
      );

      return () => {
        GoogleAds.removeSlot('inline');
      };
    }
  }, [displayState, adUnitPath, targeting, inView, isLarge]);

  /* 
    Below code is added to capture the ad click event
    As advert is rendering in the iframe, we can't capture the click event. `blur` event fired when element/window loses focus 
    (on iframe click or link opens in new tab/window), we are capturing that plus we are making sure that active element was advert. 
    If the clicked element is advert then we are firing click event to advert container so that we can do any action(ex: firing analytics event). 
    We are ensuring `blur` event *will* fire by focusing window with `window.focus()`. Throttle is added to prevent multiple taps/clicks.
    */
  useEffect(() => {
    const thisHandler = handler({ containerId: 'inline' });

    window.focus();
    window.addEventListener('blur', thisHandler);

    return () => {
      window.removeEventListener('blur', thisHandler);
    };
  }, []);

  useDeepCompareEffect(() => {
    if (
      isNotBlank(playerAdsState.current) &&
      isNotBlank(playerAdsState.current.companions)
    ) {
      const companionToRender =
        playerAdsState.current.companions.find(companion => {
          return (
            (companion.height === MOBILE_INLINE_AD_SIZES[1] &&
              companion.width === MOBILE_INLINE_AD_SIZES[0]) ||
            (companion.height === DESKTOP_INLINE_AD_SIZES[1] &&
              companion.width === DESKTOP_INLINE_AD_SIZES[0])
          );
        }) ?? null;
      if (companionToRender) {
        setCompanion(<CompanionAd companion={companionToRender} />);
        setDisplayState({
          displayAd: false,
          companionAd: true,
        });
      } else {
        setCompanion(null);
        setDisplayState(previousProps => ({
          ...previousProps,
          companionAd: false,
        }));
      }
    } else {
      setCompanion(null);
      setDisplayState(previousProps => ({
        ...previousProps,
        companionAd: false,
      }));
    }
  }, [playerAdsState.current]);

  const inlineSizeProps = useMemo(
    () => (isLarge ? { width: 728, height: 90 } : { width: 320, height: 50 }),
    [isLarge],
  );

  return displayAdsEnabled && display ?
      <Wrapper isCompanion={displayState.companionAd}>
        <InlineAdContainer
          data-section={_section ?? 'default'}
          data-test={
            displayState.companionAd ? 'inline-ad-companion' : 'inline-ad-slot'
          }
          data-type={displayState.companionAd ? 'companion' : 'ad'}
          id={displayState.companionAd ? 'inline-companion' : 'inline'}
          onClick={displayState.displayAd ? adClickHandler : undefined}
          ref={ref}
          {...inlineSizeProps}
        >
          {displayState.companionAd ? companion : null}
        </InlineAdContainer>
      </Wrapper>
    : null;
}
